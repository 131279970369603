<template>
  <el-dialog :append-to-body="true" title="查看三维资源" :fullscreen="true" :visible.sync="visible"
             :before-close="handleClose">
    <div class="allDialog">
      <div class="topInfoBox">
        <el-row :gutter="10" class="m_b1" style="width: 100%">
          <el-card class="box-card">
            <div class="flex_b_c f_w">
              <el-tooltip effect="dark" :content="resourceInfo['resource_name']" placement="top-start">
                <div class="omit">{{ resourceInfo['resource_name'] }}</div>
              </el-tooltip>
              <el-button type="text" size="medium" @click="resourcesDateShow = !resourcesDateShow">
                {{ resourcesDateShow ? '收起' : '展开' }}
                <i :class="[resourcesDateShow ?'el-icon-arrow-up' : 'el-icon-arrow-down']"
                   style="padding-left: 8px"></i>
              </el-button>
            </div>
            <div :class="[ 'resourcesDate', resourcesDateShow ? 'resourcesDate2' : '']">
              <el-col :span="6" v-for="(item, index) in dynamicForm.filter(item=>{return item.fieldName != '资源名称'})"
                      :key="index">
                <el-col :span="6" class="f_w text_right">{{ item.fieldName }}:</el-col>
                <el-col :span="18">
                  <div v-if="item.fieldEname=='resource_type'">
                    {{ $dictUtils.getDictLabel("resource_type", resourceInfo[item.fieldEname], '-') }}
                  </div>
                  <div v-else-if="item.fieldEname=='resource_state'">
                    {{ $dictUtils.getDictLabel("resource_state", resourceInfo[item.fieldEname], '-') }}
                  </div>
                  <div v-else-if="item.fieldEname=='on_collection'">
                    {{ resourceInfo[item.fieldEname] === 0 ? '否' : '是' }}
                  </div>
                  <div v-else-if="item.fieldEname=='update_user'">
                    {{ resourceInfo.updateUserName }}
                  </div>
                  <div v-else-if="item.fieldEname=='create_user'">
                    {{ resourceInfo.createUserName }}
                  </div>
                  <el-tooltip effect="dark" :content="resourceInfo[item.fieldEname]" placement="top-start" v-else>
                    <div class="omit">{{ resourceInfo[item.fieldEname] }}</div>
                  </el-tooltip>
                </el-col>
              </el-col>
            </div>
          </el-card>
          <div class="collInfoStyle">
            <el-col :span="6" v-for="(item,index) in collInfoList" :key="index">
              <el-col :span="6" class="f_w text_right">{{ item.fieldName }}:</el-col>
              <el-col :span="18">
                <el-tooltip v-if="item.fieldName != '藏品编号'" effect="dark" :content="resourceInfo[item.fieldEname]" placement="top-start">
                  <div class="omit">{{ resourceInfo[item.fieldEname] }}</div>
                </el-tooltip>
                <el-tooltip v-else effect="dark" :content="resourceInfo[item.fieldEname] || resourceInfo[item.fieldEname2]" placement="top-start">
                  <div class="omit">{{ resourceInfo[item.fieldEname] || resourceInfo[item.fieldEname2] }}</div>
                </el-tooltip>
              </el-col>
            </el-col>
          </div>
        </el-row>
      </div>
      <el-row class="detailBox" style="width: 100%">
        <el-col :span="18" class="infoBox">
          <div class="modelBox" v-if="visible">
            <iframe v-if="!resourceExists" id="mainIframe" ref="mainIframe" name="mainIframe" class="iframe"
                    :src="iframeSrc" frameborder="0" width="100%" height="100%"/>
            <div class="modelBox text_center" v-if="resourceExists">
              <img src="../../../../../../assets/img/upload.png" class="m_t2">
              <div class="m_t2">暂无资源信息哦~</div>
            </div>
          </div>
          <div class="flex_b_c">
            <div>
              <el-tag
                  :key="tag"
                  v-for="(tag, index) in dynamicTags" v-if="index < 3"
                  :disable-transitions="false">
                {{ tag.labelName }}
              </el-tag>
              <el-popover v-if="dynamicTags.length > 3"
                          placement="right"
                          width="400"
                          trigger="click">
                <el-tag
                    :key="tag"
                    v-for="(tag, index) in dynamicTags" v-if="index > 2"
                    :disable-transitions="false">
                  {{ tag.labelName }}
                </el-tag>
                <el-button slot="reference" size="small" class="moreBtn">...</el-button>
              </el-popover>
            </div>
            <div class="flex_r_c">
              <el-tooltip class="item" effect="dark" content="测量" placement="top">
                <el-button size="small" type="primary"
                           v-if="!resourceExists && hasPermission('admin:rangingThree')"
                           plain @click="rangingThree" circle>
                  <i class="icon-celiangleixing iconfont"/>
                </el-button>
              </el-tooltip>
              <el-button size="small" type="primary"
                         v-if="!resourceExists && hasPermission('admin:resources:download')"
                         plain @click="downloadThree"
                         icon="el-icon-download"
                         circle></el-button>
              <el-select class="m_l1" v-model="modelType" @change="changeSelect" placeholder="请选择"
                         style="width: 150px">
                <el-option
                    v-for="item in modelTypeOpt"
                    :key="item.id"
                    :label="item.fieldName"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
        </el-col>
        <el-col :span="6" class="infoBox">
          <el-form size="small" :model="inputForm" ref="inputForm" label-width="100px" disabled>
            <el-form-item label="文件名称：" prop="fileName">
              <el-input v-model="inputForm.fileName"></el-input>
            </el-form-item>
            <el-form-item label="文件描述：" prop="fileDesc">
              <el-input v-model="inputForm.fileDesc"></el-input>
            </el-form-item>
            <el-form-item label="文件大小：" prop="fileSizeFormat">
              <el-input v-model="inputForm.fileSizeFormat"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <div slot="footer" class="dialog-footer text_center">
      <el-button size="small" type="primary" v-if="pageType == 'workbench'" v-noMoreClick @click="audit(0)">通过</el-button>
      <el-button size="small" type="primary" v-if="pageType == 'workbench'" v-noMoreClick @click="audit(1)">驳回</el-button>
      <el-button size="small" @click="handleClose()">关闭</el-button>
    </div>
    <ResourceAuditForm ref="resourceAuditForm" @refreshDataList="getAudit()"></ResourceAuditForm>
  </el-dialog>
</template>

<script>
import ResourceAuditForm from '../../../resourceManagement/resourceAuditForm'

export default {
  components: {ResourceAuditForm},
  data() {
    return {
      visible: false,
      inputForm: {},
      fileList: [],
      resourceInfo: {},
      // 标签
      dynamicTags: [],
      modelType: '',
      resourceId: '',
      objFile: '',
      mtlFile: '',
      imgFile: '',
      stlFile: '',
      iframeSrc: '/static/threeJs/examples/basics_obj.html',
      threeIframeType: true,
      dynamicForm: [
        {
          fieldName: '资源简称',
          fieldEname: 'resource_foshot',
        },
        {
          fieldName: '主题标题',
          fieldEname: 'resource_title',
        },
        {
          fieldName: '成文时间',
          fieldEname: 'written_time',
        },
        {
          fieldName: '资源描述',
          fieldEname: 'resource_desc',
        },
        {
          fieldName: '资源来源',
          fieldEname: 'resource_source',
        },
      ],
      iframeWin: {},
      moduleId: "946049961995272192",
      modelTypeOpt: [],
      resourceExists: false,//是否展示占位图
      onRecycle: 0,//0表示不是从回收站跳过来的，1表示是
      retrievedDetailsPage: false,//首页详情跳转
      pageType: '',  // 页面类型

      resourcesDateShow: false,
      collInfoList: [
        {
          fieldName: '藏品名称',
          fieldEname: 'collection_name',
        },
        {
          fieldName: '藏品编号',
          fieldEname: 'generalNum',
          fieldEname2: 'helpNum',
        },
        {
          fieldName: '藏品类型',
          fieldEname: 'collectionType',
        },
        {
          fieldName: '藏品分类',
          fieldEname: 'oneTypeName',
        },
      ],
    }
  },
  methods: {
    init(row, onRecycle, retrievedDetailsPage, pageType) {
      this.pageType = pageType
      this.resourceId = row.id
      this.retrievedDetailsPage = retrievedDetailsPage
      if (onRecycle != null) {
        this.onRecycle = onRecycle
      }
      this.visible = true
      this.$nextTick(() => {
        // this.getDynamicForm()
        this.getResourceInfo()
      })
    },

    // 审核
    audit(type) {
      if (!this.hasPermission('resourceAuditList:pass') && type == 0 || !this.hasPermission('resourceAuditList:reject') && type == 1) {
        this.$message.info('暂无审核权限，请联系管理员！')
        return
      }
      this.$refs.resourceAuditForm.init([this.resourceId], type)
    },
    getAudit() {
      this.visible = false
      this.$emit('refreshDataList')
    },

    //右上角弹窗关闭
    handleClose() {
      this.visible = false;
    },

    // 获取动态字段
    // getDynamicForm() {
    //   var that = this;
    //   let parma = {
    //     masterTableId: this.moduleId,
    //     pageType: 0
    //   }
    //   that.$axios(this.api.dyyg.getTableByType, parma, "get").then((res) => {
    //     if (res.status) {
    //       that.dynamicForm = res.data
    //     }
    //   })
    // },

    // 资源详情
    getResourceInfo() {
      let param = {
        resourceId: this.resourceId,
        delFlag: this.onRecycle
      }
      this.$axios(this.api.dyyg.showThreeResource, param, 'get').then(data => {
        if (data && data.status) {
          this.resourceInfo = data.data.resources
          this.fileList = data.data.stringMapMap
          this.dynamicTags = data.data.infoLabel
          this.getFieldByResourceName();
        }
      })
    },
    // 获取业务字段
    getFieldByResourceName() {
      this.resourceExists = false;
      var that = this;
      let parma = {
        resourcesId: this.resourceId
      }
      that.$axios(this.api.dyyg.resourceModel, parma, "get").then((res) => {
        if (res.status) {
          if (!res.data.length) {
            this.resourceExists = true;
            this.$message('无三维文件,请上传三维文件或设置主显')
          }
          that.modelTypeOpt = res.data
          that.modelType = that.modelTypeOpt[that.modelTypeOpt.length - 1].id
          that.changeSelect(that.modelType)
        }
      })
    },

    changeSelect(id, iframeSrc) {
      var that = this
      let newarr = this.fileList[id];
      if (id == '955785177131384832') {
        this.$message('预览高模文件，请保持客户端内存充足')
      }
      if (!newarr) {
        this.$message('无三维文件，请上传三维文件或设置主显')
        this.resourceExists = true;
        return;
      }
      let iframeData
      if (newarr.obj && newarr.mtl && newarr.img) {
        this.iframeSrc = iframeSrc ? iframeSrc : '/static/threeJs/examples/basics_obj.html'
        this.inputForm = newarr.obj
        that.objFile = newarr.obj
        that.mtlFile = newarr.mtl
        that.imgFile = newarr.img
        iframeData = {
          mtlUrl: newarr.mtl.url,
          objUrl: newarr.obj.url,
          imgUrl: newarr.img,
        }
      } else if (newarr.stl) {  // stl模型
        this.iframeSrc = '/static/threeJs/examples/basics_stl.html'
        this.inputForm = newarr.stl
        iframeData = {
          stlUrl: newarr.stl.url,
        }
      } else {
        this.$message('缺失文件')
        this.resourceExists = true;
        return;
      }
      // 使用ref 获取 dom
      const mapFrame = this.$refs['mainIframe']
      // 因为iframe页面打开就已经加载 获取接口成功后刷新他
      mapFrame.contentWindow.location.reload()
      if (mapFrame.attachEvent) {
        // 兼容浏览器判断 // iframe的加载完成函数
        mapFrame.attachEvent('onload', function () {
          const iframeWin = mapFrame.contentWindow
          //传递参数
          iframeWin.postMessage(iframeData, '*')
        })
      } else {
        // iframe的加载完成函数
        mapFrame.onload = function () {
          const iframeWin = mapFrame.contentWindow
          //传递参数
          iframeWin.postMessage(iframeData, '*')
        }
      }
      return
    },
    downloadThree() {
      this.$message.info("资源文件在进行下载中，请勿重复点击")
      // 原生OBJ下载方式
      const a = document.createElement('a')

      // stl 下载方式
      if (this.inputForm.fileFormat === 'stl') {
        const stlUrl = this.inputForm.url
        const stlName = this.inputForm.fileName
        fetch(stlUrl).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
          a.href = URL.createObjectURL(blob)
          a.download = stlName, // 下载文件的名字
              document.body.appendChild(a)
          a.click()
          this.saveLog(stlName)
        })
        return
      }

      // 这里是将url转成blob地址，
      var that = this;
      let parma = {
        resourceId: this.resourceId,
        fileId: this.objFile.id,
        name: this.objFile.fileName
      }
      that.$axios(this.api.dyyg.saveRecode, parma, "get").then((res) => {
      })
      let parma1 = {
        resourceId: this.resourceId,
        fileId: this.mtlFile.id,
        name: this.mtlFile.fileName
      }
      that.$axios(this.api.dyyg.saveRecode, parma1, "get").then((res) => {
      })

      let parma2 = {
        resourceId: this.resourceId,
        fileId: this.imgFile.id,
        name: this.imgFile.fileName
      }
      that.$axios(this.api.dyyg.saveRecode, parma2, "get").then((res) => {
      })
      this.saveLog(this.mtlFile.fileName, this.mtlFile.id)
      this.saveLog(this.objFile.fileName, this.objFile.id)

      let urls = [this.mtlFile.url, this.objFile.url]
      this.imgFile.map(item => {
        this.saveLog(item.fileName, item.id)
        urls.push(item.url)
      })
      this.$nextTick(() => {
        this.$axios(this.api.dyyg.downThreeZip, urls, "post", 'blob').then((res) => {
          if (res.status) {
            let blob = new Blob([res.data], {type: 'application/zip'})
            let link = document.createElement('a')
            link.download = decodeURI('三维资源')
            link.href = window.URL.createObjectURL(blob)
            link.click()
          }
        })
      })
    },
    // 保存日志
    saveLog(fileName, id) {
      this.$axios(this.api.dyyg.saveFileLog, {
        'fileId': id ? id : this.inputForm.id,
        'operationType': '下载文件',
        'resourceId': this.resourceId,
        'fileName': fileName,
      }, 'post').then()
    },
    // 测距
    rangingThree() {
      this.threeIframeType = !this.threeIframeType
      if(!this.threeIframeType) {
        this.changeSelect(this.modelType, '/static/threeJs/examples/test_obj.html')
      } else {
        this.changeSelect(this.modelType)
      }
    },
  }
}
</script>

<style scoped>
.topInfoBox .item {
  padding: 15px 5px;
}

.topInfoBox .f_w {
  color: #333333;
}

.infoBox {
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding: 15px;
  font-size: 13px;
}

.detailBox .infoBox {
  height: calc(100vh - 76px);
  overflow-y: auto;
}

.modelBox {
  height: calc(100vh - 164px);
  overflow-y: hidden;
  margin-bottom: 15px;
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
}

.moreBtn {
  margin-left: 10px;
}

.resourcesDate {
  height: 18px;
  overflow-y: hidden;
}

.resourcesDate2 {
  height: auto;
}

.collInfoStyle {
  margin-top: 15px;
  padding: 20px;
  height: 60px !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}

.allDialog {
  height: calc(100vh - 180px);
  overflow-y: auto;
}
</style>
